.main, .footer {
  background-color: #f2f0ef;
}

.landing-page .container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
  width: 100%; 
}

.image-column {
  display: flex;
  align-content: center;
  justify-content: center; 
  align-items: center;    
  height: 100%;          
}

.landing {
  font-size: 1.3vw;
  font-weight: 400;
  text-align: left;
  align-items: center;
  align-content: center;
  justify-content: center;
}

/* .landing-about {
  font-size: 20px;
  font-weight: 500;
  color: white;
  text-shadow: 
        2px 2px 0 #000, 
        -1px -1px 0 #000,
        1px -1px 0 #000, 
        -1px 1px 0 #000; 
  text-align: left;
  align-items: center;
  align-content: center;
  justify-content: center;
} */

.landing-header {
  margin-bottom: 5px;
  font-size: 2.75vw;
  font-weight: bolder;
  color: white;
  text-shadow: 
        3px 3px 0 #000, /* 1px right, 1px down, black shadow */
        -1px -1px 0 #000, /* 1px left, 1px up, black shadow */
        1px -1px 0 #000, /* 1px right, 1px up, black shadow */
        -1px 1px 0 #000; /* 1px left, 1px down, black shadow */
}

.landing .landing-button {
  margin: 6px 6px 6px 6px;
  background-color: rgba(24, 100, 171, 0);
  border-radius: 0px;
  border: 1px solid black;
  height: 40px;
  width: 120px;
  color: #262b2e;
  font-size: 1em;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.6);
}

.landing-link {
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  background: none;
  pointer-events: auto;
}

.landing-link:hover, .landing-link:focus { 
  color: inherit;
  background: none;
  text-decoration: none; 
  outline: none;
}

.landing-link .link-icon {
  visibility: hidden; 
  opacity: 0;         
  transition: opacity 0.3s ease, visibility 0s linear 0.3s; 
}

.landing-link:hover .link-icon {
  visibility: visible; 
  opacity: 1;          
  transition: opacity 0.3s ease, visibility 0s linear 0s; 
}


.landing-education-header {
  display: flex;
  font-size: 1.5vw;
  justify-content: space-between;
  align-items: center;
  font-weight: bolder;
}

.landing-education-body {
  font-size: 1.2vw;
  font-weight: 400;
}

.landing .row {
  padding: 10px 0 10px 0;
  align-content: 'center';
  align-items: 'center';
  justify-content: 'center';
}

.footer {
  padding-top: 10px;
  padding-bottom: 40px;
  /* border-top: 1px solid rgb(30, 43, 56); */
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.card-footer {
  text-align: right;
  align-items: center;
  align-content:  center;
  justify-content: center;
}

.card-footer .footer-button {
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 5px 5px 5px 5px;
  background-color: rgba(24, 100, 171, 0);
  border: 1px solid black;
  height: 2.344vw;
  width: 8.796vh;
  color: #262b2e;
  font-size: 0.83vw;
  font-weight: 600;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.6);
}

.footer .footer-button {
  margin: 6px 6px 6px 6px;
  background-color: rgba(24, 100, 171, 0);
  border: 1px solid black;
  height: 40px;
  width: 40px;
  color: #262b2e;
  font-size: 1em;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.6);
}


.about {
  display: flex;
  align-items: center;
}

.projects {
  margin: 0 40px 0 40px;
  text-align: center;
}

.project-individual {
  text-align: center;
}



.master-roadmap {
  text-align: center;
}

.master-roadmap .col {
  margin: 0px 10px 0px 10px;
}

[class^="project-"] {
  margin: 20px 20px 20px 20px;
  text-align: left;
  text-shadow: none;
}

[class^="project-"] .card-title {
  font-size: 1.042vw;
  font-weight: 500;
}

[class^="project-"] .card-body .card-text {
  font-size: 0.833vw;
  font-weight: 400;
}

[class^="roadmap-card-"] {
  margin: 10px 0px 10px 0px;
  text-align: left;
}

[class^="roadmap-card-"] .card-header .card-title{
  text-align: left;
  font-size: 0.833vw;
  font-style: italic;
  letter-spacing: 0.5px;
}


[class^="roadmap-card-"] .card-body .card-text{
  font-size: 0.667vw;
  font-weight: bold;
  letter-spacing: 0.3px;
}

[class^="roadmap-card-"] .card-footer .card-text{
  font-size: 0.625vw;
  font-weight: bold;
  font-style: normal;
}
